.social__media {
    @include flexbox(row, wrap, center, center, center);
    @include media('<phone') {
        justify-content: space-between; }
    li {
        width: 3.2rem;
        margin: 0 1.4rem;
        @include media('<phone') {
            width: 6.3rem; }
        &:first-of-type {
            margin-left: 0; }
        &:last-of-type {
            margin-right: 0; }
        svg {
            width: 100%;
            // &:hover
            //     @media (hover: hover)
            //         path
 } }            //             fill: $color-primary
    //Dark Theme
    // .dark-theme &
    //     svg
    //         &:hover
    //             @media (hover: hover)
    //                 path
 }    //                     fill: $color-secondary
