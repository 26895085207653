%container--page-wrapper {
    margin-top: 7rem;
    padding: 5rem 2.5rem;
    @include flexbox(row, wrap, center, flex-start, flex-start);
    overflow-x: hidden;
    @include media('<tablet-portrait') {
        margin-top: 3rem; }
    //     padding: 5rem
    @include media('<phone') {
        margin-top: 5rem;
        padding: 5rem 3rem; } }

.width--full {
    padding-left: 0!important;
    padding-right: 0!important; }

%cover {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    @include flexbox(row, wrap, center, center, center); }
