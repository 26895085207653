footer {
    z-index: 3;
    width: 100%;
    padding: 2.5rem 2.5rem 2rem 2.5rem;
    border-top: 0.2rem dashed $color-off-black;
    @include flexbox(row, wrap, space-between, center, center);
    background: $color-off-white;
    text-transform: uppercase;
    position: fixed;
    bottom: 0;
    left: 0;
    @include media('<tablet-portrait') {
        @include text--body--01;
        border-top-width: 0.3rem; }
    @include media('<phone') {
        padding: 2rem 3rem; }
    .footer__navigation {
        width: 50%;
        @include flexbox(row, wrap, flex-end, center, center);
        gap: 4rem;
        @include media('<tablet-portrait') {
            width: 100%;
            margin-top: 2rem;
            justify-content: space-between;
            position: relative;
            .button__contact {
                position: absolute;
                bottom: calc(100% + 2rem);
                right: 0; } }
        @include media('<phone') {
            margin-top: 1rem;
            .button__contact {
                bottom: calc(100% + 1rem);
                padding: 0; } }
        .button {
            &:hover {
                @media (hover: hover) {
                    color: $color-dark-gray; } } } }
    //Dark Theme
    .dark-theme & {
        border-top-color: $color-off-white;
        background: $color-off-black; } }
