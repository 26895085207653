.home {
    @extend %container--page-wrapper;
    padding-bottom: 0;
    height: calc(100vh - 12rem);
    overflow-y: hidden;
    @media (min-aspect-ratio: 2/1) {
        padding-top: 3rem; }
    @include media ('>=tablet-portrait', 'portrait') {
        margin-top: 12rem; }
    @include media('<tablet-portrait') {
        height: calc(100vh - 20rem); }
    @include media('<phone') {
        height: calc(100vh - 12rem); }
    @include media('<tablet-portrait', 'landscape') {
        height: auto; } }


.home__wrapper {
    width: 100%;
    height: 100%;
    @include flexbox(column, wrap, flex-start, flex-start, flex-start);
    position: relative;
    @include media('<phone') {
        height: auto;
        min-height: 85vh; }
    &::before {
        content: '';
        width: 100%;
        height: 37vh;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url('../../../../public/images/home/home_image_1x.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include media('>=desktop') {
            background-image: url('../../../../public/images/home/home_image_2x.png'); }
        @media (min-aspect-ratio: 2/1) {
            height: 45%; }
        @include media('>tv') {
            max-height: 34rem; }
        @include media ('>=tablet-portrait', 'portrait') {
            height: 50vh; }
        @include media('<tablet-landscape') {
            height: 60%; }
        @include media('<tablet-portrait') {
            height: 45%; }
        @include media('<phone') {
            display: none; } } }

.home__header {
    width: 100%;
    @include flexbox(row, wrap, space-between, flex-start, flex-start);
    @include media('<tablet-portrait') {
        order: 2;
        margin: 3rem 0;
        padding-bottom: 14rem;
        position: relative;
        &::before {
            content: 'LatAm Roots';
            text-transform: uppercase;
            width: auto;
            position: absolute;
            top: 100%;
            left: 0;
            color: $color-dark-gray; }
        &::after {
            content: 'Internet Souls';
            text-transform: uppercase;
            width: auto;
            position: absolute;
            top: 100%;
            right: 0;
            color: $color-dark-gray; } }
    @include media('<phone') {
        margin: 5rem 0 0 0;
        padding-bottom: 6rem;
        transition: margin 1s ease;
        &.positioned {
            margin-top: 8rem; } }
    @include media('<tablet-portrait', 'landscape') {
        padding-bottom: 50rem; }
    .home__title {
        width: 45%;
        @include media('<tablet-portrait') {
            width: 65%; }
        @include media('<phone') {
            width: 90%; } }
    .home__description {
        width: auto;
        max-width: 41%;
        @include media('<tablet-portrait') {
            max-width: 30%; }
        @include media('<phone') {
            max-width: 100%;
            margin-top: 1rem; } } }

.home__coordinates {
    width: 100%;
    margin: 5rem 0;
    position: relative;
    padding-right: 15.5%;
    @media (min-aspect-ratio: 2/1) {
        margin: 3rem 0; }
    @include media('<tablet-portrait') {
        order: 1;
        padding-right: 0;
        margin-bottom: 0; }
    @include media('<phone') {
        transition: all 1s ease-out;
        &.hiding {
            margin-top: -20rem;
            opacity: 0; }
        &.hidden {
            display: none; } }
    @include media('>=tablet-portrait') {
        &.translated {
            .coordinate {
                &:nth-of-type(1) {
                    .shuffle-text {
                        &:nth-of-type(1), &:nth-of-type(2) {
                            transform: translateX(28rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(14rem); } }
                        &:nth-of-type(3) {
                            transform: translateX(-13rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(-7rem); } } } }
                &:nth-of-type(2) {
                    .shuffle-text {
                        &:nth-of-type(1), &:nth-of-type(2) {
                            transform: translateX(57rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(29rem); } }
                        &:nth-of-type(3) {
                            transform: translateX(18rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(9rem); } } } }
                &:nth-of-type(3) {
                    .shuffle-text {
                        &:nth-of-type(1), &:nth-of-type(2) {
                            transform: translateX(5rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(2.5rem); } }
                        &:nth-of-type(3) {
                            transform: translateX(-42rem);
                            @include media ('>=tablet-portrait', 'portrait') {
                                transform: translateX(-21rem); } } } } } } }
    .shuffle-text {
        transition: transform 0.6s ease-out; }
    .coordinates__additional {
        width: auto;
        height: 100%;
        padding-top: 2.3rem;
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
        @include flexbox(column, wrap, space-between, flex-end, flex-end);
        @include media('<tablet-portrait') {
            display: none; }
        .shuffle-text {
            display: block;
            width: 100%; } } }

.home__image {
    width: 100%;
    margin-top: 6rem;
    flex-grow: 1;
    height: 20vh;
    overflow: hidden;
    order: 4;
    img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center; } }
