// Colors
// $color-black: #1D1E30
$color-off-black: #151515;
// $color-middle-black: #282831
$color-dark-gray: #5F5F5F;
// $color-middle-gray: #DCDEFD
// $color-light-gray: #EEEFFB
// $color-purple-gray: #9CA0C0
$color-off-white: #F5F5F5;
// $color-white: #F5F5F5


//Gradients
// $gradient-accent: linear-gradient(90deg, #96FFA0 0%, #7D80F6 67.19%, #5C50A7 100%)
// $gradient-light-overlap: linear-gradient(180deg, #E2E3EF 0%, #FBFBFD 100%)
// $gradient-dark-overlap: linear-gradient(4deg, #717584 3.15%, #292932 79.77%)
// $gradient-light-card-hover: linear-gradient(180deg, $color-light-gray 0%, rgba(233, 234, 255, 0) 100%)
// $gradient-secondary: linear-gradient(90deg, #84F091 33.85%, #8BDFF1 100%)
// $gradient-primary: linear-gradient(270deg, $color-primary 0.09%, #A4A8FA 103.92%)

//Borders/Strokes
// $border-light-soft: 0.2rem solid $color-white
// $border-dark-soft: 0.2rem solid #393945
// $border-dark-hard: 0.2rem solid #53535E

//Shadows
// $shadow-light-soft: 0rem 1.6rem 4rem rgba(173, 173, 173, 0.1)
// $shadow-light-soft-mobile: 0rem 1.6rem 2.5rem rgba(173, 173, 173, 0.1)
// $shadow-dark-soft: 0rem 1.6rem 4rem #3A3A44
// $shadow-dark-soft-mobile: 0rem 1.6rem 2.5rem #3A3A44
