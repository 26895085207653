.text__slider {
    position: relative;
    overflow: hidden;
    height: 3rem;
    @include flexbox ( column, wrap, center, center, center);
    color: $color-dark-gray;
    ul {
        width: auto;
        @include flexbox ( row, nowrap, center, flex-start, flex-start);
        position: absolute;
        left: -100vw;
        z-index: 1;
        &.second {
            left: 0; }
        li {
            white-space: nowrap;
            margin: 0 2rem;
            position: relative; } } }
