// @mixin title--heading--01
//     font-size: 6rem
//     font-weight: $font-bold
//     letter-spacing: calc(-8rem * 0.045)
//     line-height: 6rem
//     @include media('<phone')
//         font-size: 8rem
//         line-height: 8rem
//         letter-spacing: calc(-8rem * 0.045)
// @mixin title--heading--02
//     font-size: 4rem
//     letter-spacing: calc(-4rem * 0.045)
//     line-height: 4rem
//     @include media('<phone')
//         font-size: 6.4rem
//         letter-spacing: calc(-6.4rem * 0.045)
//         line-height: 8rem
// @mixin title--heading--03
//     font-size: 3.2rem
//     letter-spacing: calc(-3.2rem * 0.045)
//     line-height: 4rem
//     @include media('<phone')
//         font-size: 5.6rem
//         letter-spacing: calc(-5.6rem * 0.045)
//         line-height: 6rem
// @mixin title--heading--04
//     font-size: 2.8rem
//     letter-spacing: calc(-2.8rem * 0.045)
//     line-height: 3rem
//     @include media('<phone')
//         font-size: 4.8rem
//         letter-spacing: calc(-4.8rem * 0.045)
//         line-height: 6rem
// @mixin title--heading--05
//     font-size: 2.4rem
//     letter-spacing: calc(-2.4rem * 0.045)
//     line-height: 3rem
//     @include media('<phone')
//         font-size: 4.4rem
//         letter-spacing: calc(-4.4rem * 0.045)
//         line-height: 6rem

// %title--heading--01
//     @include title--heading--01
// %title--heading--02
//     @include title--heading--02
// %title--heading--03
//     @include title--heading--03
// %title--heading--04
//     @include title--heading--04
// %title--heading--05
//     @include title--heading--05


// *.title--heading
//     color: inherit
//     &--01
//         @extend %title--heading--01
//     &--02
//         @extend %title--heading--02
//     &--03
//         @extend %title--heading--03
//     &--04
//         @extend %title--heading--04
//     &--05
//         @extend %title--heading--05

@mixin title--display--01 {
    color: inherit;
    font-family: $font-alt;
    text-transform: uppercase;
    font-size: 3.6rem;
    letter-spacing: calc(-3.6rem * 0.047);
    line-height: 1;
    @include media('<tablet-portrait') {
        font-size: 5.3rem;
        letter-spacing: calc(-5.3rem * 0.047); }
    @include media('<phone') {
        font-size: 5.8rem;
        letter-spacing: calc(-5.8rem * 0.047); } }
@mixin title--display--02 {
    color: inherit;
    font-family: $font-alt;
    text-transform: uppercase;
    font-size: 2.4rem;
    letter-spacing: calc(-2.4rem * 0.047);
    line-height: 1.65;
    @include media('<phone') {
        font-size: 3.2rem;
        letter-spacing: calc(-3.2rem * 0.047); } }

%title--display--01, .title--display--01 {
    @include title--display--01; }
%title--display--02, .title--display--02 {
    @include title--display--02; }
