.about {
    @extend %container--page-wrapper;
    padding-bottom: 10rem;
    @include media ('>=tablet-portrait', 'portrait') {
        margin-top: 12rem; }
    @include media('<tablet-portrait') {
        margin-top: 8rem;
        padding-bottom: 18rem; }
    @include media('<phone') {
        margin-top: 10rem;
        padding-bottom: 20rem; } }

.about__wrapper {
    width: 100%;
    min-height: 90vh;
    position: relative;
    padding: 0 0 1.2rem 14rem;
    @include media('<tablet-portrait') {
        padding-left: 8rem; } }

.about__content {
    width: 100%;
    @include flexbox(row, wrap, space-between, flex-start, flex-start);
    @include media('<phone') {
        order: 1; }
    .about__content__text {
        width: 54%;
        @extend %text--body--01;
        @include flexbox(row, wrap, space-between, flex-start, flex-start);
        @include media ('>=tablet-portrait', 'portrait') {
            width: 100%; }
        @include media('<tablet-portrait') {
            width: 100%; }
        p {
            width: 49%;
            @include media('<tablet-portrait') {
                width: 47%; }
            @include media('<phone') {
                width: 100%; } } }
    .about__content__image {
        width: 45%;
        position: relative;
        overflow: hidden;
        @include media ('>=tablet-portrait', 'portrait') {
            width: 100%; }
        @include media('<tablet-portrait') {
            width: 100%;
            margin-top: 2rem; }
        @include media('<phone') {
            order: 3; }
        picture {
            &.coord__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transform: scale(1);
                transition: transform 0.5s ease-in;
                &.visible {
                    opacity: 1;
                    transform: scale(1.2);
                    transition: transform 5s ease-in; } } } } }

.about__coordinates {
    width: 100%;
    @extend %text--body--02;
    margin: 9rem 0 3rem 0;
    @include media('<phone') {
        order: 2;
        margin-top: 3rem;
        font-size: 1.6rem; }
    .coordinate {
        margin-bottom: 0.5rem;
        cursor: pointer;
        @include media('<phone') {
            justify-content: flex-start; }
        &:hover {
            @media (hover: hover) {
                color: $color-off-black; } }
        .shuffle-text {
            &:nth-of-type(1) {
                width: 21%;
                @include media('<phone') {
                    order: 3;
                    width: 24%;
                    text-align: right; } }
            &:nth-of-type(2) {
                @include media('<phone') {
                    width: 30%; } }
            &:nth-of-type(3) {
                @include media('<phone') {
                    width: 45%; } } } }
    // Dark Mode
    .dark-theme & {
        .coordinate {
            &:hover {
                @media (hover: hover) {
                    color: $color-off-white; } } } } }


.about__mission {
    margin: 4rem 0;
    @include flexbox(row, wrap, space-between, flex-start, flex-start);
    @include media('<tablet-portrait') {
        margin-top: 8rem; }
    .about__mission__tags {
        width: 54%;
        @include flexbox(row, wrap, flex-start, center, center);
        gap: 8rem;
        color: $color-dark-gray;
        @include media('<phone') {
            width: 100%; }
        .shuffle-text {
            @extend %text--body--02;
            padding: 0.5rem 0;
            text-transform: uppercase;
            @include media('<phone') {
                font-size: 1.6rem; } } }

    .about__mission__text {
        width: 44%;
        @include media ('>=tablet-portrait', 'portrait') {
            width: 80%;
            margin-top: 2rem; }
        @include media('<tablet-portrait') {
            width: 80%;
            margin-top: 2rem; }
        @include media('<phone') {
            width: 100%; }
        .about__title {
            @include media('<phone') {
                font-size: 3.4rem;
                letter-spacing: calc(-3.4rem * 0.047); }
            span {
                display: block;
                width: 100%;
                margin-top: 5rem;
                font-size: 2.4rem;
                @include media('<phone') {
                    font-size: 2.7rem;
                    margin-top: 1rem; } } } } }

.about__pronouncement {
    width: 100%;
    margin: 0;
    padding-top: 7rem;
    @include flexbox(row, wrap, space-between, flex-start, flex-start);
    @include media('<tablet-portrait') {
        padding-top: 15rem; }
    @include media('<phone') {
        padding-top: 10rem; }
    .pronouncement__column {
        &:nth-of-type(1) {
            width: 26%;
            @include media('<tablet-portrait') {
                width: 100%;
                @include flexbox(row, wrap, space-between, flex-end, flex-end); }
            @include media ('>=tablet-portrait', 'portrait') {
                width: 100%;
                @include flexbox(row, wrap, space-between, flex-end, flex-end); } }
        &:nth-of-type(2) {
            width: 25%;
            @include media ('>=tablet-portrait', 'portrait') {
                width: 75%; }
            @include media('<tablet-portrait') {
                width: 75%; }
            @include media('<phone') {
                width: 100%; } }
        &:nth-of-type(3) {
            width: 45%;
            @include media ('>=tablet-portrait', 'portrait') {
                width: 75%; }
            @include media('<tablet-portrait') {
                width: 75%; }
            @include media('<phone') {
                width: 100%; } } }
    .language__switch__wrapper {
        width: 100%;
        margin: 1rem 0 2rem 0;
        @include flexbox(row, wrap, flex-start, center, center);
        @include media('<tablet-portrait') {
            width: auto;
            margin: 0; }
        .button {
            @extend %title--display--02;
            margin-left: 1.6rem;
            &:first-of-type {
                margin-left: 0; }
            // display: none
            // &:not(.active)
 } }            //     display: block
    .pronouncement__title {
        @include media('<tablet-portrait') {
            width: 50%; }
        @include media('<phone') {
            width: 100%; } }
    .pronouncement__intro {
        @extend %text--body--01;
        margin-top: 5rem;
        @include media ('>=tablet-portrait', 'portrait') {
            width: 75%;
            margin-bottom: 8rem; }
        @include media('<tablet-portrait') {
            width: 75%;
            margin-bottom: 8rem; }
        @include media('<phone') {
            width: 100%;
            margin-bottom: 3rem; } }
    .pronouncement__text {
        @extend %text--body--01; }
    p {
        margin-bottom: 2rem; }
    .pronouncement__footer {
        width: 100%;
        padding: 0 0 0 55%;
        margin-top: 2rem;
        @include media ('>=tablet-portrait', 'portrait') {
            margin-top: 5rem;
            padding: 0; }
        @include media('<tablet-portrait') {
            margin-top: 5rem;
            padding: 0; } } }
