.container {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  padding: 7rem 40rem 7rem 40rem;
  padding-right: 40rem;
  background-color: black;

  @media screen and (max-width: 768px) {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 14rem;
  }

  .texts-july {
    line-height: 28px;
    font-family: "Courier";
    font-size: 18px;

    @media screen and (max-width: 650px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .buttons-july {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Courier";
    border: 1px solid #f5f5f5;
    width: fit-content;
    height: fit-content;
    padding: 1.5rem 3rem;
    font-size: 18px;
    color: white;

    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }

  .buttons-july-black {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Courier";
    border: 1px solid #151515;
    width: fit-content;
    height: fit-content;
    padding: 1.5rem 3rem;
    font-size: 18px;
    color: #151515;

    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }

  .grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    .grid-item {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
    .welcome {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      .textsTitle {
        padding: 1.8rem 2.4rem;
        .texts-july {
          color: #0b0b0b;
        }

        span {
          font-family: "Courier";
          white-space: pre-wrap;
          font-size: 14px;
          color: #7e7e7e;
          text-decoration: underline;
        }
      }
    }

    .contentBlack {
      background-color: #151515;

      .textsWhite {
        padding: 1.8rem 2.4rem 3rem 2.4rem;

        display: flex;
        flex-direction: column;
        gap: 20px;
        .texts-july {
          color: white;
        }
      }
    }

    .contentWhite {
      background-color: #f5f5f5;

      .textsBlack {
        padding: 1.8rem 2.4rem 3rem 2.4rem;

        display: flex;
        flex-direction: column;
        gap: 20px;
        .texts-july {
          color: #0b0b0b;
        }
      }
    }

    .footer {
      background-color: #151515;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      .logo {
        padding: 4.4rem 0;
        width: 17.2rem;
      }

      .socialIcons {
        padding-bottom: 5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 100px;

        a {
          border: none;
        }

        img {
          width: 32px;
          height: 32px;
          filter: invert(1);
        }
      }
    }
  }
}
