.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    @include flexbox(row, wrap, center, center, center);
    .preloader__box {
        width: auto;
        min-width: 85rem;
        text-transform: uppercase;
        @include media('<phone') {
            width: 90%;
            min-width: 90%; }
        .preloader__header {
            width: 100%;
            @include flexbox(row, wrap, space-between, center, center);
            transition: opacity 0.6s 0s ease-out, transform 0.6s 0s ease-out;
            @include media('<phone') {
                align-items: flex-end;
                align-content: flex-end; }
            .preloader__logo {
                width: 19rem;
                @include media('<phone') {
                    width: 21rem; } }
            p {
                @include media('<phone') {
                    font-size: 1.1rem; } } }
        .preloader__progress {
            width: 100%;
            margin: 1rem 0;
            height: 3.2rem;
            border: 1px solid $color-off-black;
            background-color: $color-off-white;
            overflow: hidden;
            position: relative;
            transition: opacity 0.6s 0.2s ease-out, transform 0.6s 0.2s ease-out;
            @include media('<phone') {
                margin: 2rem 0; }
            &::before {
                content: '';
                width: 150%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, $color-off-black 64.06%, $color-off-white 100%);
                animation: progressGradient 9s ease forwards; } }
        .preloader__coordinates {
            width: 100%;
            @include flexbox(row, wrap, space-between, center, center);
            gap: 1rem;
            transition: opacity 0.6s 0.4s ease-out, transform 0.6s 0.4s ease-out;
            @include media('<phone') {
                font-size: 1.1rem;
                gap: 0; }
            .shuffle-text {
                width: auto;
                display: inline-block; } }
        &.loaded {
            .preloader__header {
                opacity: 0;
                transform: translateY(-2rem); }
            .preloader__progress {
                opacity: 0;
                transform: translateY(-2rem); }
            .preloader__coordinates {
                opacity: 0;
                transform: translateY(-2rem); } } }
    //Dark Theme
    .dark-theme & {
        .preloader__logo {
            filter: brightness(0) invert(1); }
        .preloader__progress {
            border-color: $color-off-white;
            background-color: $color-off-black;
            &::before {
                background: linear-gradient(90deg, $color-off-white 64.06%, $color-off-black 100%); } } } }


//Animations
@keyframes progressGradient {
    from {
        transform: translateX(-75%); }
    to {
        transform: translateX(0); } }
