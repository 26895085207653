.container {
  padding: 7rem 0;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .img {
    width: 100%;
    height: auto;
  }
}
