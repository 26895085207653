.button {
    @extend %text--body--01;
    text-transform: uppercase;
    font-family: $font-primary;
    cursor: pointer;
    &.underline {
        text-decoration: underline; }
    &--switch-mode {
        width: 5.5rem;
        height: 1.6rem;
        border: 1px solid $color-off-black;
        background: linear-gradient(90deg, $color-off-white 30.65%, $color-off-black 70.6%);
        @include media('<phone') {
            width: 9rem;
            height: 2.5rem; } }
    &--square {
        position: relative;
        padding-left: 3rem;
        &::before {
            content: '';
            width: 1.8rem;
            height: 1.8rem;
            border: 1px solid $color-off-black;
            position: absolute;
            top: calc(50% - 0.8rem);
            left: 0; }
        &:active, &.active {
            &:not(:disabled), &:not([disabled]) {
                &::before {
                    background: $color-off-black; } } }
        &:hover {
            &:not(:disabled), &:not([disabled]) {
                @media (hover: hover) {
                    &::before {
                        background: $color-off-black; } } } } } }
//     &:disabled, &[disabled]
//     a
//     @include media('<phone')
//     //Add when button has no link
//     &.no-link
//     //Hierarchy
//     &--primary
//         &:hover
//             &:not(:disabled), &:not([disabled])
//                 @media (hover: hover)
//         &:active
//             &:not(:disabled), &:not([disabled])
//     //Size
//     &--medium
//         a
//         &.no-link
//     &--small
//         a
//         &.no-link
//     //Type
//Dark Theme
.dark-theme {
    .button {
        &--switch-mode {
            border-color: $color-off-white;
            background: linear-gradient(90deg, $color-off-black 0%, $color-off-white 51.04%); }
        &--square {
            &::before {
                border-color: $color-off-white; }
            &:active, &.active {
                &:not(:disabled), &:not([disabled]) {
                    &::before {
                        background: $color-off-white; } } }
            &:hover {
                &:not(:disabled), &:not([disabled]) {
                    @media (hover: hover) {
                        &::before {
                            background: $color-off-white; } } } } } } }
//         &--primary
//             &:disabled, &[disabled]
//             &:hover
//                 &:not(:disabled), &:not([disabled])
//                     @media (hover: hover)
//             &:active
//                 &:not(:disabled), &:not([disabled])

.navigation__link {
    @extend %text--body--01;
    text-transform: uppercase; }
