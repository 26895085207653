*, *:after, *:before {
    box-sizing: border-box;
    letter-spacing: inherit;
    transition: all 0.4s $ease-out-expo;
    scroll-behavior: smooth; }

 :root {
    color-scheme: light dark; }

html {
    // position: fixed
    // overflow: hidden

    font-size: calc(100vw / 1716 *10);
    //Desktop monitors vertical
    @include media ('>=tablet-portrait', 'portrait') {
        font-size: calc(100vw / 1080 *10); }
    @include media('<tablet-portrait') {
        font-size: calc(100vw / 1080 *10); }
    @include media('<phone') {
        font-size: calc(100vw / 680 *10); } }

body {
    font-family: $font-primary;
    font-size: 1.6rem;
    background: $color-off-white;
    color: $color-off-black;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
        background-color: $color-off-white;
        border-left: 1px solid $color-off-black; }
    &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: $color-off-white; }
    &::-webkit-scrollbar-thumb {
        background-color: $color-off-black; }
    &:not(.dark-theme) {
        .picture--dark {
            display: none!important; }
        .picture--light {
            display: block!important; } }
    //Dark Theme
    &.dark-theme {
        background: $color-off-black;
        color: $color-off-white;
        &::-webkit-scrollbar-track {
            background-color: $color-off-black; }
        &::-webkit-scrollbar {
            background-color: $color-off-black; }
        &::-webkit-scrollbar-thumb {
            background-color: $color-off-white; }
        .picture--dark {
            display: block!important; }
        .picture--light {
            display: none!important; } } }

.App {
    opacity: 0;
    animation: fadeIn 1s 0.2s linear forwards; }
@keyframes fadeIn {
    from {
        opacity: 0; }
    to {
        opacity: 1; } }

a {
    text-decoration: none;
    outline: none;
    color: inherit; }

img {
    width: 100%;
    height: auto;
    vertical-align: middle;
    // opacity: 0
    // transition: opacity 0.4s ease
    // &.loaded
 }    //     opacity: 1

button {
    color: inherit;
    background: none;
    outline: none;
    cursor: pointer;
    border: none; }

.hide__mobile {
    @include media('<phone') {
        display: none!important; } }
.hide__desktop {
    @include media('>=phone') {
        display: none!important; } }
