.container {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  padding: 7rem 40rem 7rem 40rem;
  padding-right: 40rem;
  background-color: black;

  @media screen and (max-width: 768px) {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 14rem;
  }

  .texts-july {
    line-height: 28px;
    font-family: "Courier";
    font-size: 18px;

    @media screen and (max-width: 650px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .buttons-july {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Courier";
    border: 1px solid #f5f5f5;
    width: fit-content;
    height: fit-content;
    padding: 1.5rem 3rem;
    font-size: 18px;
    color: white;

    @media screen and (max-width: 650px) {
      font-size: 14px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
    .welcome {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      .textsTitle {
        padding: 1.8rem 2.4rem;
        .texts-july {
          color: black;
        }

        span {
          font-family: "Courier";
          white-space: pre-wrap;
          font-size: 14px;
          color: #7e7e7e;
          text-decoration: underline;
        }
      }
    }

    .viralcureCount {
      background-color: black;

      .viralcureCountTexts {
        padding: 1.8rem 2.4rem 3rem 2.4rem;

        display: flex;
        flex-direction: column;
        gap: 20px;
        .texts-july {
          color: white;
        }
      }
    }

    .imunoX {
      background-color: #f5f5f5;

      .texts-july {
        padding: 1.8rem 5rem;
        color: black;
        @media only screen and (max-width: 600px) {
          padding: 1.8rem 2.4rem;
        }
      }

      display: flex;
      flex-direction: column;
      .textsGrid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .scientist {
        padding: 1.8rem 5rem;
        display: flex;

        @media only screen and (max-width: 650px) {
          padding: 1.8rem 2.4rem;
          img {
            width: 50%;
          }
        }
      }
    }

    .findTheOthers {
      background-color: black;

      .texts-july {
        padding: 1.8rem 5rem;
        color: white;
        @media only screen and (max-width: 600px) {
          padding: 1.8rem 2.4rem;
        }
      }

      > div {
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(2, 1fr);
        @media only screen and (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }

        a {
          width: 100%;
          margin-top: 3rem;
        }
      }
    }

    .artemis {
      background-color: #f5f5f5;

      .artemisTexts {
        padding: 0 5rem;

        display: flex;
        flex-direction: column;
        gap: 20px;
        @media only screen and (max-width: 600px) {
          padding: 0 2.4rem;
        }

        .texts-july {
          color: black;
        }
      }
    }
    .paradox {
      padding-bottom: 2.4rem;

      .textsParadox {
        padding: 0 5rem;
        @media only screen and (max-width: 600px) {
          padding: 0 2.4rem;
        }
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: black;
        .texts-july {
          color: white;
        }
      }
    }

    .sthorm {
      background-color: #f5f5f5;
      div {
        padding: 0 5rem;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media only screen and (max-width: 600px) {
          padding: 0 2.4rem;
        }
        .texts-july {
          color: black;
        }
      }
    }

    .socialIcons {
      padding-bottom: 5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 100px;

      a {
        border: none;
      }

      img {
        width: 32px;
        height: 32px;
        filter: invert(1);
      }
    }
  }
}
